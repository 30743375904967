import { styled } from '@mui/material/styles';

import Button from '@/components/ui/Button/Button';

export const FloatingButtonWrapper = styled('div')(({ theme }) => ({
  position: 'fixed',
  bottom: 36,
  right: 36,
  zIndex: theme.zIndex.fab,
}));

export const FloatingButton = styled(Button)(() => ({
  boxShadow: '0 2px 12px 0 rgba(0, 0, 0, 0.23)',
  borderRadius: 28,
  fontSize: '14px',
  padding: '16px 24px',

  '&:hover': {
    backgroundColor: 'var(--dark-orange)',
  },
}));
