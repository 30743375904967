import { Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import DirectRequestForm from '@/components/DirectRequestForm/DirectRequestForm';
import FloatingCTA from '@/components/FloatingCTA/FloatingCTA';
import useFormatMessage from '@/hooks/useFormatMessage';
import { useMobileMediaQuery } from '@/hooks/useMobileMediaQuery';

import { FormWrapper, PageMargins, Wrapper } from './Hero.style';

export default function Hero() {
  const [floatingCTA, setFloatingCTA] = useState(false);
  const directRequestFormRef = useRef();
  const formatMessage = useFormatMessage();
  const mobileMediaQuery = useMobileMediaQuery();

  const handleCTAClick = () => {
    const additionalSpaceAboveForm = 150;
    window.scrollBy({
      top:
        directRequestFormRef.current.getBoundingClientRect().top -
        additionalSpaceAboveForm,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (directRequestFormRef.current) {
        setFloatingCTA(
          directRequestFormRef.current.getBoundingClientRect().bottom <
            (mobileMediaQuery ? 0 : 67)
        );
      }
    };
    window.addEventListener('scroll', handleScroll, true);

    return () => window.removeEventListener('scroll', handleScroll, true);
  }, [mobileMediaQuery]);

  return (
    <>
      <Wrapper>
        <PageMargins>
          <Typography
            align="center"
            component="h1"
            variant={mobileMediaQuery ? 'h2' : 'h1'}
            mt={0}
            mb={3}>
            {formatMessage('hero_headline')}
          </Typography>
          <FormWrapper>
            <DirectRequestForm ref={directRequestFormRef} />
          </FormWrapper>
        </PageMargins>
      </Wrapper>
      {floatingCTA && <FloatingCTA onClick={handleCTAClick} />}
    </>
  );
}
