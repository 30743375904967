import PropTypes from 'prop-types';
import React from 'react';

import { trackFloatingCTAButtonClick } from '@/helpers/dataLayer';
import useFormatMessage from '@/hooks/useFormatMessage';

import { FloatingButton, FloatingButtonWrapper } from './style';

export default function FloatingCTA({ onClick }) {
  const formatMessage = useFormatMessage();

  const handleOnClick = () => {
    trackFloatingCTAButtonClick();
    onClick();
  };

  return (
    <FloatingButtonWrapper className="mui-fixed">
      <FloatingButton
        className="mrkt-hp-floatingButton"
        data-cy="button: floating button"
        onClick={handleOnClick}
        size="large">
        {formatMessage('floating_button_CTA')}
      </FloatingButton>
    </FloatingButtonWrapper>
  );
}

FloatingCTA.propTypes = {
  onClick: PropTypes.func.isRequired,
};
